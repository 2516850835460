<div class="dropdown">
  <button class="dropbtn">Size</button>
  <div class="dropdown-content">
    <a
      *ngFor="let size of sizes"
      [class.active]="key == size.value"
      [routerLink]="['/shop/products']"
      [queryParams]="{ productSize: size?.value }"
    >
      {{ size.title }}
    </a>
  </div>
</div>
