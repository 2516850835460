<div class="dropdown">
  <button class="dropbtn">Category</button>
  <div class="dropdown-content">
    <a
      *ngFor="let category of category"
      [class.active]="key == category.value"
      [routerLink]="['/shop/products']"
      [queryParams]="{ launchCategory: category?.value }"
    >
      {{ category.title }}
    </a>
  </div>
</div>
