import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
  isVisible?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor() { }

  public screenWidth = window.innerWidth;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "home",
      type: "link",
      path: "/home",
      active: true,
    },
    {
      title: "Products",
      type: "link",
      path: "/shop/products",
      active: false,
    },
    // {
    //   title: "services",
    //   type: "link",
    //   path: "/pages/services",
    //   // active: false,
    // },
    {
      path: "/pages/blog",
      title: "Blog",
      type: "link",
    },
    {
      path: "/pages/projects",
      title: "Projects",
      type: "link",
    },
    {
      path: "/pages/catalog",
      title: "catalog",
      type: "link",
    },

    { path: "/pages/aboutus", title: "About us", type: "link" },
    {
      path: "/pages/contact",
      title: "Contact us",
      type: "link",
      isVisible: this.screenWidth > 700,
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
