export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyDKThXvJJKTWc8YgVRLv2XUFsgD775gG1Y",
    authDomain: "spectra-tiles-web.firebaseapp.com",
    projectId: "spectra-tiles-web",
    storageBucket: "spectra-tiles-web.appspot.com",
    messagingSenderId: "733141435514",
    appId: "1:733141435514:web:6c0df24581aceccd8ae7c3",
    measurementId: "G-G04JZ2SF26",
  },

  production: false,
};
