import { Component, Input, OnInit } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"],
})
export class LeftMenuComponent implements OnInit {
  @Input() selected;
  key = "";

  types = [
    {
      value: "Polish",
      title: "Polish",
    },
    {
      value: "Mat",
      title: "Matt",
    },

    {
      value: "Crystal finish",
      title: "Crystal finish",
    },
    {
      value: "Silk matt",
      title: "Silk matt",
    },
    {
      value: "Dark",
      title: "Dark",
    },
    {
      value: "elegance",
      title: "Elegance",
    },
    {
      value: "metalic",
      title: "metalic",
    },
    {
      value: "Wooden",
      title: "Wooden",
    },
    {
      value: "Others",
      title: "Others",
    },
  ];
  public collapse: boolean = true;

  constructor(public navServices: NavService) {}

  ngOnChanges(change: any) {
    if (change?.selected?.currentValue != change?.selected?.previousValue) {
      this.selected = change?.selected?.currentValue;
      this.key = this.selected[Object.keys(this.selected)[0]];
      // console.log(this.key, "key");
    }
  }

  ngOnInit(): void {
    this.key = this.selected[Object.keys(this.selected)[0]];
  }
}
