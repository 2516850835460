<!--footer section -->
<footer [class]="class">
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <!-- <div class="footer-title footer-mobile-title">
            <h4>about</h4>
          </div> -->
          <div class="footer-contant">
            <!-- <img [src]="themeLogo" alt="logo" /> -->
            <div class="logo">
              <img src="../../../../assets/images/logo.jpeg" alt="" class="w-100" />
            </div>
            <p>
              Welcome to Spectra Tiles, your one-stop destination for premium
              quality tiles and tile accessories. We specialize in providing a
              wide range of exquisite tiles that are both aesthetically pleasing
              and durable.
            </p>
            <!-- <div class="footer-social">
              <ul>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="col offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Categories</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li *ngFor="let category of category">
                  <a [routerLink]="['/shop/products']" [queryParams]="{ launchCategory: category?.value }">
                    {{ category.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>visit</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li>
                  <a href="javascript:void(0)" routerLink="home
                  ">Home</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/shop/products">Products</a>
                </li>
                <!-- <li>
                  <a href="javascript:void(0)" routerLink="/pages/services">Services</a>
                </li> -->
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/blog">Blog</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/projects">Projects</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/aboutus">About Us</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/catalog">Catalog</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>store information</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li>
                  <i class="fa fa-map-marker"></i>Spectra Tiles Demo Store,
                  <!-- Unit 278-287 station road Ha1 1na, london , United Kingdom -->
                  Unit 16 Dragon's court Fishponds Bristol BS5 7XX
                </li>
                <li><i class="fa fa-phone"></i>Call Us: 07738477325</li>
                <li>
                  <i class="fa fa-envelope-o"></i>Email Us:
                  <a>info@spectratiles.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }}
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="text-center fw-bold">
            Made with ❤️ by
            <a href="https://angriotechnologies.com/" target="_blank">Angrio Technologies</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->