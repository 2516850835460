<div class="h-100" *ngIf="isVisible">
  <div class="h-100 w-100 bg">
    <div class="box">
      <div class="position-absolute">
        <a class="btn text-end" (click)="closePopup()"
          ><i class="fa fa-close text-white"></i
        ></a>
      </div>
      <h4 class="text-center py-2 fw-bold text-white">Subscription</h4>

      <div class="d-flex align-items-center">
        <input
          placeholder="Enter Your Email"
          type="email"
          [(ngModel)]="userEmail"
        />
        <a
          routerlink="/pages/contact"
          class="btn btn-solid m-0"
          (click)="onSendClick(userEmail)"
        >
          Send
        </a>
      </div>

      <h5 class="text-secondary fw-bold text-center my-3">
        Subscribe Our NewsLatter !!
      </h5>
    </div>
  </div>
</div>
