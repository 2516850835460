<div class="dropdown">
  <button class="dropbtn">Type</button>
  <div class="dropdown-content">
    <a
      *ngFor="let type of types"
      [class.active]="key == type.value"
      [routerLink]="['/shop/products']"
      [queryParams]="{ productType: type?.value }"
    >
      {{ type.title }}
    </a>
  </div>
</div>
